import ReactModal from "react-modal";

const LaneStatusFillModal = ({
  showModal,
  handleCloseModal,
  modalHeight,
  modalTop,
  activityIdentifierChangeHandler,
  selectedLaneStatus,
  allLaneStatuses,
}) => {
  const modalStyle = {
    content: {
      background: "transparent",
      border: "none",
      width: "0px",
    },
  };
  return (
    <div>
      <ReactModal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
        style={modalStyle}
        ariaHideApp={false}
      >
        <div className="modal">
          <div
            className="modal__content"
            style={{ height: `${modalHeight}vh`, top: `${modalTop}%` }}
          >
            <div className="modal__header">
              <span>
                <div
                  className="customerInfo-icon-modal"
                  style={{
                    transform: "translateY(0px)",
                  }}
                >
                  <i className="fa fa-info" aria-hidden="true"></i>
                </div>
              </span>
              <div className="modal__head">
                <h2 className="modal__heading">Import Lanes Status</h2>
              </div>
            </div>
            <div>
              <h3
                style={{
                  margin: "4px 0",
                  fontSize: "15px",
                  fontWeight: "500",
                }}
              >
                Lanes Status
              </h3>
              <label>Please select status of the imported lanes</label>
              <select
                value={selectedLaneStatus}
                style={{ width: "70%" }}
                onChange={(e) =>
                  activityIdentifierChangeHandler(
                    "selectedLaneStatus",
                    e.target.value
                  )
                }
              >
                <option value="" selected disabled>
                  -
                </option>
                {allLaneStatuses?.map((status) => (
                  <option key={status.id} value={status.name}>
                    {status.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="modal__buttons">
              <button
                type="button"
                className="modal__confirm-button"
                onClick={handleCloseModal}
                disabled={!selectedLaneStatus}
                style={
                  !selectedLaneStatus
                    ? {
                        color: "#a7abaa",
                        zIndex: "auto",
                        background: "#e5e5e5",
                        fontWeight: "400",
                        cursor: "not-allowed",
                      }
                    : { zIndex: "auto" }
                }
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default LaneStatusFillModal;
