import ContactsIcon from "../../../images/ImportAccountIcon.svg";
import OpportunityIcon from "../../../images/ImportOppIcon.svg";
import AccountIcon from "../../../images/AccountsBuildingIcon.svg";
import CarrierGreenIcon from "../../../images/CarrierGreenIcon.svg";
import DocumentIcon from "../../../images/DocumentIcon.svg";
import LaneIcon from "../../../images/lane.svg";

export const importTypes = [
  {
    type: "accounts",
    label: "Import Accounts",
    description:
      "Import your accounts. Accounts are companies or shippers. Duplicates are caught by exact names or websites.",
    link: "/import/account",
    image: AccountIcon,
    style: {
      textDecoration: "none",
      background: "#FFFFFF",
      color: "#E79209",
      padding: "10px 24px",
      borderRadius: "8px",
      border: "1px solid #E79209",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "150%",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    type: "carriers",
    label: "Import Carriers",
    description:
      "Import your carriers. Duplicates will be caught by matching MC#s.",
    image: CarrierGreenIcon,
    link: "/import/carrier",
    style: {
      textDecoration: "none",
      background: "#FFFFFF",
      color: "#047C6E",
      padding: "10px 24px",
      borderRadius: "8px",
      border: "1px solid #047C6E",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "150%",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    type: "deals",
    label: "Import Deals",
    description: "Import your deals or opportunities from your existing CRM.",
    link: "/import/deal",
    image: OpportunityIcon,
    style: {
      textDecoration: "none",
      background: "#FFFFFF",
      color: "#D32F2F",
      padding: "10px 24px",
      borderRadius: "8px",
      border: "1px solid #D32F2F",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "150%",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    type: "contacts",
    label: "Import Contacts",
    description:
      "Import your contacts. Duplicates are caught by matching emails or matching mobile numbers.",
    link: "/import/contact",
    image: ContactsIcon,
    style: {
      textDecoration: "none",
      background: "#FFFFFF",
      color: "#1778F2",
      padding: "10px 24px",
      borderRadius: "8px",
      border: "1px solid #1778F2",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "150%",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    type: "activities",
    label: "Import Activities",
    description:
      "Import your notes and activities from your previous CRM or spreadsheet.",
    link: "/import/activity",
    image: DocumentIcon,
    style: {
      textDecoration: "none",
      background: "#FFFFFF",
      color: "#5959C0",
      padding: "10px 24px",
      borderRadius: "8px",
      border: "1px solid #5959C0",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "150%",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    },
  },
  {
    type: "lanes",
    label: "Import Lanes",
    description: "Import your lanes in accounts and carriers",
    link: "/import/lane",
    image: LaneIcon,
    style: {
      textDecoration: "none",
      background: "#FFFFFF",
      color: "#000",
      padding: "10px 24px",
      borderRadius: "8px",
      border: "1px solid #000",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "150%",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    },
  },
];
