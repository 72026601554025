import React, { useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  // DirectionsService,
  DirectionsRenderer,
  Marker,
} from "@react-google-maps/api";

// Inside InfoBox.js
function InfoBox({ distance, duration }) {
  return (
    <div className="info-box-check-hassan">
      <p>Distance: {distance}</p>
      <p>Duration: {duration}</p>
    </div>
  );
}

function GoogleMapComponent({ origin, destination, setMileage }) {
  const mapStyles = {
    height: "400px",
    width: "100%",
  };
  const originLoc = { lat: origin.lat, lng: origin.long }; // First location
  const destinationLoc = { lat: destination.lat, lng: destination.long }; // Second location

  const [center, setCenter] = useState({ lat: 0, lng: 0 }); // Initial center
  const [directions, setDirections] = useState(null);
  const [zoom, setZoom] = useState(10); // Initial zoom level
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [mapStyle, setMapStyle] = useState(null); // To store the selected style
  const mapRef = useRef(null); // Reference to the map instance

  // Light and Dark Styles (Example JSON from Google Maps Styling Wizard)
  const lightStyle = [
    {
      elementType: "geometry",
      stylers: [{ color: "#f5f5f5" }],
    },
    {
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      elementType: "labels.text.fill",
      stylers: [{ color: "#616161" }],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [{ color: "#f5f5f5" }],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [{ color: "#bdbdbd" }],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [{ color: "#eeeeee" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#e5e5e5" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#ffffff" }],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#dadada" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#616161" }],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [{ color: "#e5e5e5" }],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [{ color: "#eeeeee" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#c9c9c9" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
  ];

  const darkStyle = [
    {
      elementType: "geometry",
      stylers: [{ color: "#212121" }],
    },
    {
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [{ color: "#212121" }],
    },
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "administrative.country",
      elementType: "labels.text.fill",
      stylers: [{ color: "#9e9e9e" }],
    },
    {
      featureType: "administrative.land_parcel",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: "#bdbdbd" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "#181818" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ color: "#616161" }],
    },
    {
      featureType: "road",
      elementType: "geometry.fill",
      stylers: [{ color: "#2c2c2c" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: "#8a8a8a" }],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [{ color: "#373737" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: "#3c3c3c" }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [{ color: "#212a37" }],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{ color: "#f3d19c" }],
    },
    {
      featureType: "road.local",
      elementType: "geometry",
      stylers: [{ color: "#2c2c2c" }],
    },
    {
      featureType: "transit",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#000000" }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ color: "#3d3d3d" }],
    },
  ];

  // Function to calculate the center and zoom between two locations
  const calculateCenterAndZoom = (origin, destination) => {
    // Calculate the center point
    const newCenter = {
      lat: (origin.lat + destination.lat) / 2,
      lng: (origin.long + destination.long) / 2,
    };

    // Calculate the zoom level based on the distance between the two locations
    // You can adjust the zoom calculation as needed
    const distance = Math.sqrt(
      (origin.lat - destination.lat) ** 2 +
        (origin.long - destination.long) ** 2
    );

    const newZoom = Math.min(
      14, // Set a maximum zoom level if desired
      Math.floor(12 - Math.log2(distance)) // Adjust the zoom level calculation as needed
    );

    return { center: newCenter, zoom: newZoom };
  };

  // Function to handle user input or other triggers to update the center and zoom
  const updateCenterAndZoom = (origin, destination) => {
    const { center: newCenter, zoom: newZoom } = calculateCenterAndZoom(
      origin,
      destination
    );
    setCenter(newCenter);
    setZoom(newZoom);
  };

  useEffect(() => {
    if (origin.lat && destination.lat) updateCenterAndZoom(origin, destination);
  }, [origin.lat, destination.lat]);

  useEffect(() => {
    const directionsService = new window.google.maps.DirectionsService();

    const directionsRequest = {
      // routingPreference: "traffic_unaware",
      // polylineQuality: "high_quality",
      // computeAlternativeRoutes: false,
      // routeModifiers: {
      //   avoidTolls: false,
      //   avoidHighways: false,
      //   avoidFerries: false,
      //   avoidIndoor: false,
      // },
      destination: destinationLoc,
      origin: originLoc,
      travelMode: "DRIVING", // You can change this to 'WALKING', 'BICYCLING', etc.
    };

    directionsService.route(directionsRequest, (result, status) => {
      if (status === "OK") {
        setDirections(result);

        // Extract distance and duration from the route response
        const route = result.routes[0].legs[0];
        setDistance(route.distance.text);
        const numbers = route.distance.text.match(/\d+/g); // This will match all digits in the string
        const mileage = numbers ? numbers.join("") : "";
        setMileage && setMileage(parseFloat(mileage));
        setDuration(route.duration.text);
      } else {
        console.error("Directions request failed with status:", status);
      }
    });
  }, [origin.lat, destination.lat]);

  const handleStyleChange = (style) => {
    console.log("map aya pehle", mapRef, style);
    if (mapRef.current) {
      console.log("map aya", style);
      setMapStyle(style);
      mapRef.current.setOptions({
        styles: style,
      });
    }
  };
  console.log(
    "google map stuff",
    distance,
    duration,
    originLoc,
    destinationLoc
  );
  return (
    <>
      <div style={{ marginBottom: "10px" }}>
        {/* Custom Control for Map Styles */}
        <button onClick={() => handleStyleChange(null)}>Default</button>
        <button onClick={() => handleStyleChange(lightStyle)}>Light</button>
        <button onClick={() => handleStyleChange(darkStyle)}>Dark</button>
      </div>
      <GoogleMap
        mapContainerStyle={mapStyles}
        center={center}
        zoom={zoom}
        options={{
          gestureHandling: "greedy",
          mapTypeControl: true,
          fullscreenControl: true,
          mapTypeControlOptions: {
            style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: window.google.maps.ControlPosition.TOP_LEFT,
          },
        }}
        onLoad={(map) => (mapRef.current = map)}
      >
        {/* {directions && <DirectionsRenderer directions={directions} options={{ suppressMarkers: true }}/>}

      <DirectionsService
        options={directionsOptions}
        callback={directionsCallback}
      /> */}
        {/* Marker for the first location */}
        <Marker position={originLoc} />

        {/* Marker for the second location */}
        <Marker position={destinationLoc} />
        {directions && <DirectionsRenderer directions={directions} />}
        {/* Display distance and duration */}
      </GoogleMap>
      {distance && duration && (
        <InfoBox distance={distance} duration={duration} />
      )}
    </>
  );
}

export default GoogleMapComponent;
