import {
  FETCH_LANE_STATUSES_REQUEST,
  FETCH_LANE_STATUSES_SUCCESS,
  FETCH_LANE_STATUSES_FAILURE,
} from "./types";

import axios from "axios";
import { BASE_URL } from "../../config";

const fetchAllLaneStatusesRequest = () => {
  return {
    type: FETCH_LANE_STATUSES_REQUEST,
  };
};
const fetchAllLaneStatusesSuccess = (data) => {
  return {
    type: FETCH_LANE_STATUSES_SUCCESS,
    payload: data,
  };
};
const fetchAllLaneStatusesFailure = (err) => {
  return {
    type: FETCH_LANE_STATUSES_FAILURE,
    payload: err,
  };
};

// Fetch All LaneStatuses
export const fetchAllLaneStatuses = () => {
  return (dispatch) => {
    dispatch(fetchAllLaneStatusesRequest());
    axios({
      method: "GET",
      url: `${BASE_URL}/lanes/statuses`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((res) => {
        const data = res.data.lane_statuses;
        dispatch(fetchAllLaneStatusesSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data?.message;
        dispatch(fetchAllLaneStatusesFailure(errorMsg));
      });
  };
};
