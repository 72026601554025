export const laneHeaderMapping = {
  origin_city: "Origin City",
  origin_state: "Origin State",
  origin_zip: "Origin Zip",
  origin_address: "Origin Address",
  destination_city: "Destination City",
  destination_state: "Destination State",
  destination_zip: "Destination Zip",
  destination_address: "Destination Address",
  description: "Description",
  status: "Status",
  volume: "Volume",
  price: "Linehaul",
  equiment_types: "Equiment Types",
  modes: "modes",
  special_requirements: "Special Requirements",
  lane_types: "Lane Types",
};
