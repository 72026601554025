// DropdownLaneStatus.jsx
import React, { useEffect, useRef, useState } from "react";
import "./DropdownLaneStatus.scss";

const leadOptions = [
  {
    id: 1,
    label: "Status",
    color: "#FF9966",
    bgColor: "#FFF3EE",
  },
  {
    id: 2,
    label: "Status",
    color: "#9966FF",
    bgColor: "#F5F0FF",
  },
  {
    id: 3,
    label: "Status",
    color: "#66A3FF",
    bgColor: "#EFF6FF",
  },
  {
    id: 4,
    label: "Status",
    color: "#66CC99",
    bgColor: "#EFFAF4",
  },
  {
    id: 5,
    label: "Status",
    color: "#FF6666",
    bgColor: "#FFF1F1",
  },
  {
    id: 6,
    label: "Status",
    color: "#999999",
    bgColor: "#F5F5F5",
  },
];

const LeadOption = ({ option, className = "" }) => (
  <div className={`lead-option ${className}`}>
    <div className="lead-dot" style={{ backgroundColor: option.color }} />
    <span style={{ color: option.color }}>{option.label}</span>
  </div>
);

const DropdownLaneStatus = ({
  selectedColor,
  choiceId,
  editChoiceColorChangeHandler,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(leadOptions[0]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const color = leadOptions?.find((option) => option.color === selectedColor);
    setSelectedOption(color);
  }, [selectedColor]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (option) => {
    setSelectedOption(option);
    editChoiceColorChangeHandler(choiceId, option.color);
    setIsOpen(false);
  };

  return (
    <div className="lead-status-wrapper">
      <div className="lead-status-container">
        <div className="dropdown-container" ref={dropdownRef}>
          <button
            type="button"
            className="dropdown-trigger"
            onClick={() => setIsOpen(!isOpen)}
            style={{ backgroundColor: selectedOption.bgColor }}
          >
            <LeadOption option={selectedOption} />
            <span
              className="dropdown-arrow"
              style={{ color: selectedOption.color }}
            >
              {isOpen ? "▲" : "▼"}
            </span>
          </button>

          {isOpen && (
            <div className="dropdown-menu">
              <div style={{ fontSize: "12px", margin: "5px 0 10px 0" }}>
                Choose Status Color
              </div>
              {leadOptions.map((option) => (
                <button
                  key={option.id}
                  type="button"
                  className="dropdown-item"
                  style={{ backgroundColor: option.bgColor }}
                  onClick={() => handleSelect(option)}
                >
                  <LeadOption option={option} />
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DropdownLaneStatus;
