import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { lanesStatusColor } from "../../constants/lanes/lanesStatus";
import DashboardLoader from "../Loaders/DashboardLoader";
import "./LanesView.scss";

const FilterButton = ({ type, label, isActive, onToggle, color }) => {
  return (
    <button
      onClick={onToggle}
      className={`filter-button ${type} ${isActive ? "active" : ""}`}
      style={
        isActive
          ? {
              color,
              backgroundColor: lanesStatusColor?.[color] || "#FFF3EE",
              borderColor: `${color}33`,
            }
          : {}
      }
    >
      {label}
      {/* {activeCount && <span className="count">({activeCount})</span>} */}
    </button>
  );
};

const LanesView = ({
  allLaneStatuses,
  allLanes,
  selectedLaneStatusIds,
  toggleLaneStatusFilter,
  getAccountLanes,
  hasMoreLanes,
  setAddLane,
  addLane,
  setLaneData,
  setLaneMode,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className="lanes-view">
      <div className="filters-section">
        <div
          className="filters-label"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          Filter by status
          <i
            style={{ marginLeft: "5px" }}
            className={`fa fa-chevron-${isExpanded ? "up" : "down"}`}
            aria-hidden="true"
          ></i>
        </div>
        {isExpanded && (
          <div className="filters-container">
            {allLaneStatuses?.map((status) => (
              <FilterButton
                type="prospect"
                label={status.name}
                isActive={selectedLaneStatusIds.includes(status.id)}
                onToggle={() => toggleLaneStatusFilter(status.id)}
                color={status.color}
                key={status.id + status.name + uuidv4()}
              />
            ))}
          </div>
        )}
      </div>

      <div className="lanes-container">
        <div
          id="scrollableDiv"
          style={{ maxHeight: "700px", overflow: "auto", height: "auto" }}
        >
          <InfiniteScroll
            pageStart={1}
            loadMore={(page) => getAccountLanes(page, "nextPage")}
            hasMore={hasMoreLanes}
            loader={<DashboardLoader key={0} />}
            useWindow={false}
            initialLoad={false}
            getScrollParent={() => document.getElementById("scrollableDiv")}
          >
            {allLanes?.map((lane) => (
              <div
                className="lane-item"
                key={lane.id + uuidv4()}
                onClick={() => {
                  setAddLane(true);
                  setLaneData(lane);
                  setLaneMode("edit");
                }}
              >
                <div className="lane-header">
                  <div className="status-section">
                    <span
                      className="status-badge prospect"
                      style={{
                        color: lane?.status_color,
                        backgroundColor:
                          lanesStatusColor?.[lane?.status_color] || "#FFF3EE",
                        borderColor: `${lane?.status_color}33`,
                      }}
                    >
                      {lane?.status}
                    </span>
                    <span className="dot">•</span>
                    <span className="equipment-type">
                      {lane?.equipment_types
                        ?.map((type) => type.name)
                        ?.join(", ")}
                    </span>
                  </div>
                  <div className="date">
                    {moment(lane.created_at).format("L")}
                  </div>
                </div>

                <div className="lane-content">
                  <div className="location">
                    {lane?.origin_address
                      ?.replace(/\s*,?\s*(US|USA|United States)\s*$/i, "")
                      .trim()}
                  </div>
                  <svg className="arrow-icon" viewBox="0 0 24 24">
                    <path d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                  <div className="location">
                    {lane?.destination_address
                      ?.replace(/\s*,?\s*(US|USA|United States)\s*$/i, "")
                      .trim()}
                  </div>
                  <div className="metrics">
                    <span className="rate">${lane?.price}</span>
                    <span className="dot">•</span>
                    <span className="volume">{lane?.volume}/wk</span>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>

        <button
          className="add-lane-button"
          onClick={() => {
            setAddLane(!addLane);
            setLaneMode("create");
          }}
        >
          + Add Lane
        </button>
      </div>
    </div>
  );
};

export default React.memo(LanesView);
