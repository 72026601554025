import React, { Component } from "react";
import axios from "axios";
import "../../sass/AccountsList.scss";
import Pagination from "react-js-pagination";
import Select from "react-select";
import { debounce } from "lodash";
import AccountsLogo from "../../images/AccountsSettingsIcon.svg";
import CarrierGreenNavIcon from "../../images/CarrierGreenNavIcon.svg";
import { colourStyles } from "../../constants/selectStyles";
import GoogleAutocompleteComponent from "../GoogleComponents/GoogleAutocompleteComponent";
import RangeSlider from "../MaterialComponents/RangeSlider/RangeSlider";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
  fetchAllEquipmentTypes,
  fetchAllSpecialRequirements,
} from "../../redux";
import MapboxLanesMap from "../Mapbox/MapboxLanesMap";

class LanesMapList extends Component {
  state = {
    accounts: [],
    query: "",
    activePage: 1,
    total: 0,
    sortDirection: true,
    isApplying: true,
    carrierStatusArray: [],
    inputFocused: {
      isTrue: false,
      id: "",
      name: "",
    },
    showNameInput: {
      isTrue: false,
      id: "",
      name: "",
    },
    origin: {
      address: null,
      lat: null,
      long: null,
      city: null,
      state: null,
      zip: null,
      country: null,
    },
    destination: {
      address: null,
      lat: null,
      long: null,
      city: null,
      state: null,
      zip: null,
      country: null,
    },
    price: null,
    equipmentTypes: [],
    speciaRequirements: [],
    modes: [],
    daysOfWeek: [],
    statuses: [],
    deadHeadOrigin: 25,
    deadHeadDestination: 25,
    searchType: false,
    sortKey: "",
  };
  days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  fetchAccounts = debounce((page = 1, sortKey) => {
    const {
      origin,
      destination,
      equipmentTypes,
      speciaRequirements,
      modes,
      price,
      daysOfWeek,
      statuses,
      deadHeadOrigin,
      deadHeadDestination,
    } = this.state;
    const searchParam = this.state.searchType ? "accounts" : "carriers";
    let url = `/${searchParam}/lanes?_limit=30&_page=${page}`;
    if (sortKey) {
      const dir = this.state.sortDirection === true ? "desc" : "asc";
      url += `&sort_key=${sortKey}&sort_dir=${dir}`;
    }
    if (origin.city) {
      url += `&origin_city=${origin.city}`;
    } else if (origin.zip) {
      url += `&origin_zip=${origin.zip}`;
    } else if (origin.state) {
      url += `&origin_state=${origin.state}`;
    }
    if (origin.address && deadHeadOrigin !== 0) {
      url += `&origin_lat=${origin.lat}`;
      url += `&origin_long=${origin.long}`;
      url += `&origin_radius=${deadHeadOrigin}`;
    }
    if (destination.address && deadHeadDestination !== 0) {
      url += `&destination_lat=${destination.lat}`;
      url += `&destination_long=${destination.long}`;
      url += `&destination_radius=${deadHeadDestination}`;
    }
    if (destination.city) {
      url += `&destination_city=${destination.city}`;
    } else if (destination.zip) {
      url += `&destination_zip=${destination.zip}`;
    } else if (destination.state) {
      url += `&destination_state=${destination.state}`;
    }
    if (equipmentTypes && equipmentTypes.length > 0) {
      url += `&equipment_type_ids=${equipmentTypes
        ?.map((type) => type.value)
        .join(",")}`;
    } else {
      url += `&equipment_type_ids=`;
    }
    if (speciaRequirements && speciaRequirements.length > 0) {
      url += `&requirement_type_ids=${speciaRequirements
        ?.map((type) => type.value)
        .join(",")}`;
    } else {
      url += `&requirement_type_ids=`;
    }
    if (modes && modes.length > 0) {
      url += `&mode_ids=${modes?.map((type) => type.value).join(",")}`;
    } else {
      url += `&mode_ids=`;
    }
    if (daysOfWeek && daysOfWeek.length > 0) {
      url += `&days_of_week=${daysOfWeek?.map((day) => day.value).join(",")}`;
    } else {
      url += `&days_of_week=`;
    }
    if (statuses && statuses.length > 0) {
      url += `&status_ids=${statuses?.map((day) => day.value).join(",")}`;
    } else {
      url += `&status_ids=`;
    }
    if (price) {
      url += `&price=${price}`;
    }
    this.setState({ isApplying: true });
    axios({
      method: "GET",
      url,
    })
      .then((response) => {
        this.setState({
          accounts: response.data.lanes?.results?.map((carrier) => ({
            ...carrier,
            equipment_types: carrier.equipment_types?.map((type) => ({
              value: type.id,
              label: type.name,
            })),
            requirement_types: carrier.requirement_types?.map((type) => ({
              value: type.id,
              label: type.name,
            })),
          })),
          total: response.data.lanes?.total,
          isApplying: false,
        });
      })
      .catch((error) => this.setState({ isApplying: false }));
  }, 500);
  componentDidMount() {
    this.fetchAccounts();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.searchType !== prevState.searchType ||
      this.state.origin.address !== prevState.origin.address ||
      this.state.destination.address !== prevState.destination.address ||
      this.state.price !== prevState.price ||
      this.state.equipmentTypes?.length !== prevState.equipmentTypes?.length ||
      this.state.speciaRequirements?.length !==
        prevState.speciaRequirements?.length ||
      this.state.modes?.length !== prevState.modes?.length ||
      this.state.daysOfWeek?.length !== prevState.daysOfWeek?.length ||
      this.state.statuses?.length !== prevState.statuses?.length ||
      (this.state.origin.address &&
        this.state.deadHeadOrigin !== prevState.deadHeadOrigin) ||
      (this.state.destination.address &&
        this.state.deadHeadDestination !== prevState.deadHeadDestination)
    ) {
      this.fetchAccounts(1, this.state.sortKey);
    }
  }
  handleOriginPlaceSelect = (place) => {
    this.setState({
      origin: place,
    });
  };
  handleDestinationPlaceSelect = (place) => {
    this.setState({
      destination: place,
    });
  };
  handleDeadHeadOrigin = (e, deadHeadOrigin) => {
    this.setState({
      deadHeadOrigin,
    });
  };
  handleDeadHeadDestination = (e, deadHeadDestination) => {
    this.setState({
      deadHeadDestination,
    });
  };

  render() {
    const accounts = this.state.accounts;
    return (
      <div className="accountList">
        <h1 className="accountList__heading" style={{ marginTop: "20px" }}>
          Lanes <span style={{ color: "#4A9876" }}>{this.state.total}</span>
        </h1>
        <div className="modal-radioButton c-radioButton">
          {[
            { label: "Carriers", value: false, image: CarrierGreenNavIcon },
            { label: "Accounts", value: true, image: AccountsLogo },
          ].map(({ label, value, image }) => (
            <span key={`${label}_${value}`}>
              <label
                htmlFor={`laneType_${label}_${value}`}
                style={
                  this.state.searchType === value
                    ? {
                        border: "2px solid #4A9876",
                        outline: "none",
                        color: "#555555",
                        padding: "4px 12px",
                        borderRadius: "9px",
                        marginRight: "8px",
                        background: "#F4F6F5",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }
                    : {
                        color: "#555555",
                        padding: "4px 12px",
                        border: "none",
                        borderRadius: "9px",
                        background: "#F4F6F5",
                        marginRight: "8px",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }
                }
              >
                <img src={image} height="16px" /> {label}
              </label>
              <input
                type="radio"
                name="searchType"
                id={`laneType_${label}_${value}`}
                className="radio-button"
                checked={this.state.searchType === value}
                value={value}
                onChange={() => {
                  this.setState({
                    searchType: value,
                  });
                }}
              />
            </span>
          ))}
        </div>
        <div className="lanes-panel-form-1">
          <div className="lanes-panel-form-1-input">
            <label>Origin</label>
            <GoogleAutocompleteComponent
              onPlaceSelect={this.handleOriginPlaceSelect}
            />
            <label style={{ marginTop: "10px" }}>
              Expand Origin Search ({this.state.deadHeadOrigin} Miles)
            </label>
            <RangeSlider
              width={285}
              value={this.state.deadHeadOrigin}
              handleChange={this.handleDeadHeadOrigin}
            />
          </div>
          <div className="lanes-panel-form-1-input">
            <label>Destination</label>
            <GoogleAutocompleteComponent
              onPlaceSelect={this.handleDestinationPlaceSelect}
            />
            <label style={{ marginTop: "10px" }}>
              Expand Destination Search ({this.state.deadHeadDestination} Miles)
            </label>
            <RangeSlider
              width={285}
              value={this.state.deadHeadDestination}
              handleChange={this.handleDeadHeadDestination}
            />
          </div>
          <div className="lanes-panel-form-1-div">
            <label>Equipment Types</label>
            <Select
              value={this.state.equipmentTypes}
              closeMenuOnSelect={false}
              isMulti
              styles={colourStyles}
              options={this.props.allEquipmentTypes?.map((type) => {
                return { value: type.id, label: type.name };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                this.setState({
                  equipmentTypes: selectedOptions,
                })
              }
            />
            <label style={{ marginTop: "10px" }}>Days of Week</label>
            <Select
              value={this.state.daysOfWeek}
              closeMenuOnSelect={false}
              isMulti
              styles={colourStyles}
              options={this.days?.map((day) => {
                return { value: day, label: day };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                this.setState({
                  daysOfWeek: selectedOptions,
                })
              }
            />
          </div>
          <div className="lanes-panel-form-1-div">
            <label>Modes</label>
            <Select
              value={this.state.modes}
              closeMenuOnSelect={false}
              isMulti
              styles={colourStyles}
              options={this.props.allModes?.map((type) => {
                return { value: type.id, label: type.name };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                this.setState({
                  modes: selectedOptions,
                })
              }
            />
            <label style={{ marginTop: "10px" }}>Lane Status</label>
            <Select
              value={this.state.statuses}
              closeMenuOnSelect={false}
              isMulti
              styles={colourStyles}
              options={this.props.allLaneStatuses?.map((status) => {
                return { value: status.id, label: status.name };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                this.setState({
                  statuses: selectedOptions,
                })
              }
            />
          </div>
          <div className="lanes-panel-form-1-div">
            <label>Special Requirements</label>
            <Select
              value={this.state.speciaRequirements}
              closeMenuOnSelect={false}
              isMulti
              styles={colourStyles}
              options={this.props.allSpecialRequirements?.map((type) => {
                return { value: type.id, label: type.name };
              })}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                DropdownIndicator: () => null,
              }}
              placeholder="-Select-"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selectedOptions) =>
                this.setState({
                  speciaRequirements: selectedOptions,
                })
              }
            />
          </div>
        </div>
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={30}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            this.setState({ activePage: page });
            this.fetchAccounts(page, this.state.sortKey);
          }}
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
        />
        {this.state.isApplying ? (
          <div class="load-wrapp">
            <div class="load-3">
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
            </div>
          </div>
        ) : this.state.isApplying === false && accounts.length === 0 ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "50px",
              fontWeight: "500",
            }}
          >
            No lanes found
          </div>
        ) : (
          <MapboxLanesMap
            lanes={accounts?.map((account, index) => ({
              id: index,
              origin: {
                lat: parseFloat(account?.origin_lat),
                lon: parseFloat(account?.origin_long),
              },
              destination: {
                lat: parseFloat(account?.destination_lat),
                lon: parseFloat(account?.destination_long),
              },
              color: account?.status_color || "#FF9966",
              ...account,
            }))}
          />
        )}
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={30}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            this.setState({ activePage: page });
            this.fetchAccounts(page, this.state.sortKey);
          }}
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
        />
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
    allEquipmentTypes: state.allEquipmentTypes.equipmentTypes,
    allSpecialRequirements: state.allSpecialRequirements.specialRequirements,
    allModes: state.allModes.modes,
    allLaneStatuses: state.allLaneStatuses.laneStatuses,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    fetchAllEquipmentTypes: (equipmentTypes) =>
      dispatch(fetchAllEquipmentTypes(equipmentTypes)),
    fetchAllSpecialRequirements: (specialRequirementsData) =>
      dispatch(fetchAllSpecialRequirements(specialRequirementsData)),
  };
};

export default connect(MSP, MDP)(LanesMapList);
