export const styles = {
  container: {
    margin: "0 auto",
  },
  mapContainer: {
    width: "100%",
    height: "500px",
    position: "relative",
    marginBottom: "20px",
    marginTop: "20px",
  },
  mapStyle: {
    width: "100%",
    height: "100%",
  },
  styleControlsContainer: {
    position: "absolute",
    top: "10px",
    left: "10px",
    zIndex: 1,
    backgroundColor: "white",
    padding: "10px",
    borderRadius: "4px",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
  },
  styleButton: {
    display: "block",
    width: "100%",
    marginBottom: "5px",
    padding: "5px 10px",
    border: "none",
    borderRadius: "3px",
    backgroundColor: "#f0f0f0",
    cursor: "pointer",
    textAlign: "left",
  },
  styleButtonActive: {
    backgroundColor: "#4264fb",
    color: "white",
  },
  statsContainer: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
    marginBottom: "15px",
  },
  speedControl: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginBottom: "20px",
    padding: "15px",
    backgroundColor: "#f8f8f8",
    borderRadius: "6px",
  },
  speedInput: {
    width: "80px",
    padding: "5px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  routesContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
    gap: "20px",
  },
  routeItem: {
    padding: "15px",
    backgroundColor: "#f8f8f8",
    borderRadius: "6px",
  },
  routeHeader: {
    marginBottom: "10px",
  },
  routeDot: {
    width: "12px",
    height: "12px",
    display: "inline-block",
    marginRight: "8px",
    borderRadius: "50%",
  },
  routeInfo: {
    color: "#666",
  },
  marker: {
    fontSize: "24px",
    cursor: "pointer",
  },
  loadingMessage: {
    textAlign: "center",
    padding: "20px",
  },
};
