import React, { useEffect, useRef, useState } from "react";
import "./DropdownLaneStatus.scss";
import { lanesStatusColor } from "../../constants/lanes/lanesStatus";

const LeadOption = ({ option, className = "" }) => (
  <div className={`lead-option ${className}`}>
    <div className="lead-dot" style={{ backgroundColor: option.color }} />
    <span style={{ color: option.color }}>{option.name}</span>
  </div>
);

const DropdownLaneStatusPanel = ({
  allStatuses,
  statusId,
  editStatusChangeHandler,
  setStatusColor,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(allStatuses[0]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (statusId) {
      const color = allStatuses?.find((option) => option.id === statusId);
      setSelectedOption(color);
    }
  }, [statusId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (option) => {
    setSelectedOption(option);
    editStatusChangeHandler(option.id);
    setIsOpen(false);
    setStatusColor && setStatusColor(option.color || "#FF9966");
  };

  return (
    <div
      className="lead-status-wrapper"
      style={{ display: "inline-block", width: "auto", marginLeft: "10px" }}
    >
      <div className="lead-status-container">
        <div className="dropdown-container" ref={dropdownRef}>
          <button
            type="button"
            className="dropdown-trigger"
            onClick={() => setIsOpen(!isOpen)}
            style={{
              backgroundColor:
                lanesStatusColor?.[selectedOption.color] || "#FFF3EE",
            }}
          >
            <LeadOption option={selectedOption} />
            <span
              className="dropdown-arrow"
              style={{ color: selectedOption.color, marginTop: "2px" }}
            >
              {isOpen ? (
                <i className="fa fa-chevron-up" aria-hidden="true"></i>
              ) : (
                <i className="fa fa-chevron-down" aria-hidden="true"></i>
              )}
            </span>
          </button>

          {isOpen && (
            <div className="dropdown-menu">
              <div style={{ fontSize: "12px", margin: "5px 0 10px 0" }}>
                Choose Status
              </div>
              {allStatuses?.map((option) => (
                <button
                  key={option.id}
                  type="button"
                  className="dropdown-item"
                  style={{
                    backgroundColor:
                      lanesStatusColor?.[option.color] || "#FFF3EE",
                  }}
                  onClick={() => handleSelect(option)}
                >
                  <LeadOption option={option} />
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DropdownLaneStatusPanel;
