import React, { useState, useEffect } from "react";
import Map, {
  Source,
  Layer,
  Marker,
  NavigationControl,
  FullscreenControl,
  Popup,
} from "react-map-gl";
import * as turf from "@turf/turf";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { styles } from "./mapStyles";
import { MAPBOX_TOKEN } from "../../config";
// Redux stuff
import { connect } from "react-redux";
import { setActiveTabIndex, setTab } from "../../redux";

mapboxgl.workerClass = require("mapbox-gl/dist/mapbox-gl-csp-worker").default;

const MapboxLanesMap = ({
  lanes,
  setMileage,
  fromPanel,
  setTab,
  setActiveTabIndex,
  history,
  totalTabs,
}) => {
  const [currentStyle, setCurrentStyle] = useState("dark");
  const [routeData, setRouteData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hoverInfo, setHoverInfo] = useState(null);
  const [hoveredRouteId, setHoveredRouteId] = useState(null);
  const [viewport, setViewport] = useState({
    longitude: -95,
    latitude: 37,
    zoom: 3,
    pitch: 0,
    bearing: 0,
  });

  const mapStyles = {
    streets: "mapbox://styles/mapbox/streets-v11",
    dark: "mapbox://styles/mapbox/dark-v10",
    light: "mapbox://styles/mapbox/light-v10",
  };

  const createRouteData = (lane) => {
    try {
      const originCoords = [Number(lane.origin.lon), Number(lane.origin.lat)];
      const destCoords = [
        Number(lane.destination.lon),
        Number(lane.destination.lat),
      ];

      const line = turf.lineString([originCoords, destCoords]);
      const distance = turf.length(line, { units: "miles" });
      const roundedDistance = Math.round(distance);

      return {
        ...lane,
        distance: roundedDistance,
        geometry: line.geometry,
        origin: {
          lat: Number(lane.origin.lat),
          lon: Number(lane.origin.lon),
        },
        destination: {
          lat: Number(lane.destination.lat),
          lon: Number(lane.destination.lon),
        },
      };
    } catch (error) {
      console.error("Error creating route:", error, lane);
      return null;
    }
  };

  const processAllRoutes = () => {
    setLoading(true);
    try {
      const routes = lanes
        .map((lane) => {
          if (
            !lane.origin?.lat ||
            !lane.origin?.lon ||
            !lane.destination?.lat ||
            !lane.destination?.lon
          ) {
            console.error("Invalid coordinates for lane:", lane);
            return null;
          }
          return createRouteData(lane);
        })
        .filter((route) => route !== null);

      if (setMileage && routes.length > 0) {
        const totalMileage = routes.reduce(
          (sum, route) => sum + route.distance,
          0
        );
        setMileage(totalMileage);
      }

      setRouteData(routes);
    } catch (error) {
      console.error("Error processing routes:", error);
    } finally {
      setLoading(false);
    }
  };

  const calculateViewport = (lanes) => {
    if (!lanes.length) return viewport;

    const points = lanes.reduce((acc, lane) => {
      acc.push([Number(lane.origin.lon), Number(lane.origin.lat)]);
      acc.push([Number(lane.destination.lon), Number(lane.destination.lat)]);
      return acc;
    }, []);

    const features = turf.featureCollection(
      points.map((point) => turf.point(point))
    );
    const bbox = turf.bbox(features);
    const center = turf.center(features);

    const padding = 0.1;
    const paddedBbox = [
      bbox[0] - padding,
      bbox[1] - padding,
      bbox[2] + padding,
      bbox[3] + padding,
    ];

    const latDiff = paddedBbox[3] - paddedBbox[1];
    const lonDiff = paddedBbox[2] - paddedBbox[0];
    const maxDiff = Math.max(latDiff, lonDiff);

    let zoom = 8 - Math.log2(maxDiff);
    zoom = Math.min(Math.max(zoom, 3), 15);

    return {
      longitude: center.geometry.coordinates[0],
      latitude: center.geometry.coordinates[1],
      zoom: lanes.length === 1 ? 5 : zoom,
      pitch: 0,
      bearing: 0,
    };
  };

  useEffect(() => {
    if (lanes && lanes.length > 0) {
      const newViewport = calculateViewport(lanes);
      setViewport(newViewport);
      processAllRoutes();
    }
  }, [lanes]);

  const laneGeoJSON = {
    type: "FeatureCollection",
    features: routeData.map((route) => ({
      type: "Feature",
      geometry: route.geometry,
      properties: {
        color: route.color,
        id: route.id,
        isHovered: route.id === hoveredRouteId,
      },
    })),
  };
  console.log("routeData", routeData, "hoverInfo", hoverInfo);

  return (
    <div style={styles.container}>
      <div style={styles.mapContainer}>
        <Map
          viewState={viewport}
          style={styles.mapStyle}
          mapStyle={mapStyles[currentStyle]}
          mapboxAccessToken={MAPBOX_TOKEN}
          attributionControl={false}
          onMove={(evt) => setViewport(evt.viewState)}
          onMouseMove={(evt) => {
            const features = evt.features || [];
            if (features.length > 0) {
              const hoveredFeature = features[0];
              const route = routeData.find(
                (r) => r.id === hoveredFeature.properties.id
              );
              if (route) {
                setHoveredRouteId(route.id);
                setHoverInfo({
                  lngLat: evt.lngLat,
                  route: route,
                });
              }
            } else {
              setHoveredRouteId(null);
              setHoverInfo(null);
            }
          }}
          onMouseLeave={() => {
            setHoveredRouteId(null);
            setHoverInfo(null);
          }}
          interactiveLayerIds={["lanes-layer"]}
        >
          <NavigationControl position="top-right" />
          <FullscreenControl position="top-right" />

          <div style={styles.styleControlsContainer}>
            {Object.entries(mapStyles).map(([name, url]) => (
              <button
                key={name}
                style={{
                  ...styles.styleButton,
                  ...(name === currentStyle ? styles.styleButtonActive : {}),
                }}
                onClick={() => setCurrentStyle(name)}
              >
                {name.charAt(0).toUpperCase() + name.slice(1)}
              </button>
            ))}
          </div>

          {!loading && (
            <>
              <Source id="lanes-source" type="geojson" data={laneGeoJSON}>
                <Layer
                  id="lanes-layer"
                  type="line"
                  paint={{
                    "line-width": [
                      "case",
                      ["boolean", ["get", "isHovered"], false],
                      6,
                      4,
                    ],
                    "line-color": ["get", "color"],
                    "line-opacity": [
                      "case",
                      ["boolean", ["get", "isHovered"], false],
                      1,
                      0.8,
                    ],
                  }}
                />
              </Source>

              {!fromPanel && hoverInfo && (
                <Popup
                  longitude={hoverInfo.lngLat.lng}
                  latitude={hoverInfo.lngLat.lat}
                  anchor="top"
                  closeButton={false}
                  onClose={() => {
                    setHoverInfo(null);
                    setHoveredRouteId(null);
                  }}
                >
                  <div style={{ padding: "10px" }}>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      {hoverInfo?.route?.origin_address
                        ?.replace(/\s*,?\s*(US|USA|United States)\s*$/i, "")
                        .trim()}{" "}
                      to{" "}
                      {hoverInfo?.route?.destination_address
                        ?.replace(/\s*,?\s*(US|USA|United States)\s*$/i, "")
                        .trim()}
                    </span>
                    <div>
                      {hoverInfo?.route?.account_name ? "Account" : "Carrier"}:{" "}
                      {hoverInfo?.route.account_name ||
                        hoverInfo?.route.carrier_name}
                    </div>
                    <div>
                      Owner(s):{" "}
                      {hoverInfo?.route?.owners
                        ?.map((owner) => owner.name)
                        ?.join(", ")}
                    </div>
                    <div>
                      Eqpt Type(s):{" "}
                      {hoverInfo?.route?.equipment_types
                        ?.map((type) => type.label)
                        ?.join(", ")}
                    </div>
                    <div>Distance: {hoverInfo.route.distance} mi</div>
                  </div>
                </Popup>
              )}

              {routeData.map((route) => (
                <React.Fragment key={route.id}>
                  <Marker
                    latitude={route.origin.lat}
                    longitude={route.origin.lon}
                    anchor="center"
                  >
                    <div
                      style={{ ...styles.marker, color: route.color }}
                      title={`Origin: ${route.origin.lat}, ${route.origin.lon}`}
                    >
                      📍
                    </div>
                  </Marker>
                  <Marker
                    latitude={route.destination.lat}
                    longitude={route.destination.lon}
                    anchor="center"
                  >
                    <div
                      style={{ ...styles.marker, color: route.color }}
                      title={`Destination: ${route.destination.lat}, ${route.destination.lon}`}
                    >
                      📍
                    </div>
                  </Marker>
                </React.Fragment>
              ))}

              <Layer
                id="3d-buildings"
                source="composite"
                source-layer="building"
                type="fill-extrusion"
                minzoom={15}
                paint={{
                  "fill-extrusion-color": "#aaa",
                  "fill-extrusion-height": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    15,
                    0,
                    16.05,
                    ["get", "height"],
                  ],
                  "fill-extrusion-base": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    15,
                    0,
                    16.05,
                    ["get", "min_height"],
                  ],
                  "fill-extrusion-opacity": 0.6,
                }}
              />
            </>
          )}
        </Map>
      </div>

      {!fromPanel && (
        <div style={styles.statsContainer}>
          {loading ? (
            <div style={styles.loadingMessage}>Loading route data...</div>
          ) : (
            <div style={styles.routesContainer}>
              {routeData.map((route) => (
                <div key={route.id} style={styles.routeItem}>
                  <div style={styles.routeHeader}>
                    <span
                      style={{
                        ...styles.routeDot,
                        backgroundColor: route.color,
                      }}
                    ></span>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      {route?.origin_address
                        ?.replace(/\s*,?\s*(US|USA|United States)\s*$/i, "")
                        .trim()}{" "}
                      to{" "}
                      {route?.destination_address
                        ?.replace(/\s*,?\s*(US|USA|United States)\s*$/i, "")
                        .trim()}
                    </span>
                  </div>
                  <div style={styles.routeInfo}>
                    <div>
                      {route?.account_name ? "Account" : "Carrier"}:{" "}
                      <span
                        style={{ cursor: "pointer", color: "#41BE89" }}
                        onClick={(e) => {
                          let newTabData = {
                            type: route?.carrier_id ? "carrier" : "account",
                            id: route?.carrier_id
                              ? route?.carrier_id
                              : route?.account_id,
                            name: route?.carrier_id
                              ? route?.carrier_name
                              : route?.account_name,
                          };
                          if (e.metaKey || e.ctrlKey) {
                            setTab({ ...newTabData, blank: true });
                          } else {
                            setActiveTabIndex(totalTabs + 1);
                            setTab(newTabData);
                            history.push("/active-tabs");
                          }
                        }}
                      >
                        {route.account_name || route.carrier_name}
                      </span>
                    </div>
                    <div>
                      Owner(s):{" "}
                      {route?.owners?.map((owner) => owner.name)?.join(", ")}
                    </div>
                    <div>
                      Eqpt Type(s):{" "}
                      {route?.equipment_types
                        ?.map((type) => type.label)
                        ?.join(", ")}
                    </div>
                    <div>
                      Modes:{" "}
                      {route?.modes?.map((mode) => mode.name)?.join(", ")}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
  };
};

export default connect(MSP, MDP)(MapboxLanesMap);
